
import { TeamMember } from '../types/Team';

export const teamData: TeamMember[] = [
  {
    "id": 1,
    "name": "張代書",
    "title": "資深房貸專員",
    "description": "專精於商用不動產貸款評估，有超過15年房貸服務經驗",
    "companyName": "永昌房貸顧問公司",
    "phone": "",
    "location": "台北市松山區",
    "casesCompleted": 1,
    "totalLoanAmount": 8500000,
    "specialties": [
      "商用不動產",
      "廠房貸款",
      "企業融資"
    ]
  },
  {
    "id": 2,
    "name": "游代書",
    "title": "不動產貸款顧問",
    "description": "熟悉新北地區房產貸款與過戶流程，協助解決繁複的代書程序",
    "companyName": "新北游代書事務所",
    "phone": "",
    "location": "新北市板橋區",
    "casesCompleted": 3,
    "totalLoanAmount": 28000000,
    "specialties": [
      "房產過戶",
      "二胎貸款",
      "不動產貸款"
    ]
  },
  {
    "id": 3,
    "name": "許代書",
    "title": "代書貸款專家",
    "description": "桃園地區知名代書，擅長協助土地、房屋貸款及法律文件處理",
    "companyName": "桃園許代書事務所",
    "phone": "",
    "location": "桃園市桃園區",
    "casesCompleted": 4,
    "totalLoanAmount": 20000000,
    "specialties": [
      "法律文件",
      "不動產貸款",
      "土地買賣"
    ]
  },
  {
    "id": 4,
    "name": "賴代書",
    "title": "不動產貸款專員",
    "description": "提供專業代書服務，專注於房屋買賣、貸款及稅務規劃",
    "companyName": "桃園賴代書事務所",
    "phone": "",
    "location": "桃園市蘆竹區",
    "casesCompleted": 2,
    "totalLoanAmount": 12000000,
    "specialties": [
      "房屋買賣",
      "稅務規劃",
      "房貸代辦"
    ]
  },
  {
    "id": 5,
    "name": "王代書",
    "title": "資深房屋過戶專員",
    "description": "台南知名代書，專精房屋過戶、抵押貸款及企業不動產處理",
    "companyName": "台南王代書事務所",
    "phone": "",
    "location": "台南市安平區",
    "casesCompleted": 1,
    "totalLoanAmount": 23000000,
    "specialties": [
      "房屋過戶",
      "抵押貸款",
      "企業不動產處理"
    ]
  },
  {
    "id": 6,
    "name": "游代書",
    "title": "代書服務專家",
    "description": "精通新北房地產市場，專業協助代書事務與貸款申請流程",
    "companyName": "新北游代書事務所",
    "phone": "",
    "location": "新北市新莊區",
    "casesCompleted": 5,
    "totalLoanAmount": 21000000,
    "specialties": [
      "房屋貸款",
      "不動產過戶",
      "借貸規劃"
    ]
  }
];
