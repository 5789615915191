import React from 'react';
import { TeamMember } from '../types/Team';

interface TeamMemberDetailProps {
    member: TeamMember;
    onClose: () => void;
}

const TeamMemberDetail: React.FC<TeamMemberDetailProps> = ({ member, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-start mb-4">
                    <h2 className="text-2xl font-bold">{member.name}</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <span className="sr-only">關閉</span>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="space-y-4">
                    <div>
                        <h3 className="text-lg font-semibold text-gray-700">{member.title}</h3>
                        <p className="text-gray-600">{member.description}</p>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="font-semibold">公司</p>
                            <p className="text-gray-600">{member.companyName}</p>
                        </div>
                        <div>
                            <p className="font-semibold">地點</p>
                            <p className="text-gray-600">{member.location}</p>
                        </div>
                    </div>

                    <div>
                        <p className="font-semibold">聯絡電話</p>
                        <p className="text-gray-600">{member.phone}</p>
                    </div>

                    <div>
                        <p className="font-semibold">專長領域</p>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {member.specialties.map(specialty => (
                                <span
                                    key={specialty}
                                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                                >
                                    {specialty}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="font-semibold">已完成案件</p>
                            <p className="text-green-600 text-xl font-bold">{member.casesCompleted} 件</p>
                        </div>
                        <div>
                            <p className="font-semibold">累計放款金額</p>
                            <p className="text-green-600 text-xl font-bold">
                                {new Intl.NumberFormat('zh-TW', {
                                    style: 'currency',
                                    currency: 'TWD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                }).format(member.totalLoanAmount)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMemberDetail; 