export const ROUTES = {
  HOME: '/',                    // 主頁
  TEAM: '/team',               // 進駐團隊
  LOAN_APPLY: '/loan-apply',   // 尋求借款
  SUCCESS_CASES: '/success',   // 媒合成功案例
  AGENT_SEARCH: '/agent',      // 我是代書尋求好標的
  PROPERTY_LOAN: '/property',  // 我有房地產尋求借款
  LOAN_IN_PROGRESS: '/loan-progress', // 尋求借款中
  BLOG: '/blog/:slug',         // 部落格文章
  BLOGLIST: '/blogs'           // 部落格列表
};
