import { BlogPost } from '../types/BlogPost';

const POSTS_DATA_PATH = '/content/posts/posts-data.json';
const MARKDOWN_PATH = '/content/posts';

export const blogService = {
  async getAllPosts(): Promise<BlogPost[]> {
    try {
      const response = await fetch(POSTS_DATA_PATH);
      const data = await response.json();
      const posts = Array.isArray(data) ? data : (data.posts || []);
      return posts.sort((a: BlogPost, b: BlogPost) =>
        (new Date(a.publishDate) > new Date(b.publishDate) ? -1 : 1)
      );
    } catch (error) {
      console.error('Error fetching posts:', error);
      return [];
    }
  },

  async getPostBySlug(slug: string): Promise<BlogPost | null> {
    try {
      const metadataResponse = await fetch(POSTS_DATA_PATH);
      const data = await metadataResponse.json();
      const posts = Array.isArray(data) ? data : (data.posts || []);
      const postMetadata = posts.find((post: BlogPost) => post.slug === slug);

      if (!postMetadata) {
        console.log('Post metadata not found for slug:', slug);
        return null;
      }

      const contentResponse = await fetch(`${MARKDOWN_PATH}/${slug}.md`);
      if (!contentResponse.ok) {
        console.log('Markdown file not found for slug:', slug);
        throw new Error('Post content not found');
      }
      const content = await contentResponse.text();
      return {
        ...postMetadata,
        content
      };
    } catch (error) {
      console.error('Error fetching blog post:', error);
      return null;
    }
  },

  async getRelatedPosts(tags: string[], currentSlug: string): Promise<BlogPost[]> {
    try {
      const allPosts = await this.getAllPosts();
      
      // 過濾掉當前文章並找出相關文章
      const relatedPosts = allPosts
        .filter(post => post.slug !== currentSlug) // 排除當前文章
        .filter(post => {
          // 檢查是否有相同的標籤
          return post.tags.some(tag => tags.includes(tag));
        })
        .sort((a, b) => {
          // 計算標籤匹配數
          const aMatches = a.tags.filter(tag => tags.includes(tag)).length;
          const bMatches = b.tags.filter(tag => tags.includes(tag)).length;
          return bMatches - aMatches;
        })
        .slice(0, 3); // 只返回前3篇相關文章
      
      return relatedPosts;
    } catch (error) {
      console.error('Failed to fetch related posts:', error);
      return [];
    }
  },

  async getAdjacentPosts(currentSlug: string): Promise<{ prev: BlogPost | null; next: BlogPost | null }> {
    try {
      const allPosts = await this.getAllPosts();
      const currentIndex = allPosts.findIndex(post => post.slug === currentSlug);
      
      return {
        prev: currentIndex > 0 ? allPosts[currentIndex - 1] : null,
        next: currentIndex < allPosts.length - 1 ? allPosts[currentIndex + 1] : null
      };
    } catch (error) {
      console.error('Failed to fetch adjacent posts:', error);
      return { prev: null, next: null };
    }
  }
};
