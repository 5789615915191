import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { RealEstate } from '../types/RealEstate';
import { GOOGLE_SCRIPT_CONFIG } from '../config/constants';

// 新增金額轉換函數
const convertToChineseNumber = (amount: number): string => {
  const units = ['', '萬', '億', '兆'];
  let result = '';
  
  if (amount === 0) return '0元';
  
  let temp = amount;
  let unitIndex = 0;
  
  while (temp >= 1) {
    const part = temp % 10000;
    if (part !== 0) {
      result = part + units[unitIndex] + result;
    }
    temp = Math.floor(temp / 10000);
    unitIndex++;
  }
  
  return result + '元';
};

const SuccessCases: React.FC = () => {
  const [estates, setEstates] = useState<RealEstate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [displayCases, setDisplayCases] = useState(0);
  const [displayTotalAmount, setDisplayTotalAmount] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // 計算統計數據
  const calculateStats = useCallback(() => {
    const totalCases = estates.length;
    const totalAmount = estates.reduce((sum, estate) => 
      sum + (estate.investmentAmount || 0), 0
    );
    return { totalCases, totalAmount };
  }, [estates]);

  // 資料獲取的 useEffect
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(`${GOOGLE_SCRIPT_CONFIG.baseUrl}${GOOGLE_SCRIPT_CONFIG.endpoints.cases}`);
        const result = await response.json();
        
        if (!isMounted) return;

        if (result.status === 'success') {
          setEstates(result.data);
          setError('');
        } else {
          throw new Error(result.message || '資料格式錯誤');
        }
      } catch (error) {
        if (!isMounted) return;
        console.error('Error fetching data:', error);
        setError('資料讀取發生錯誤，請稍後再試');
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();
    
    const interval = setInterval(fetchData, 300000);
    
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  // 數字動畫效果
  useEffect(() => {
    if (!loading && !error && isFirstLoad && estates.length > 0) {
      const stats = calculateStats();
      const duration = 2000;
      const framesPerSecond = 60;
      const totalFrames = (duration / 1000) * framesPerSecond;
      
      let frame = 0;
      let animationFrameId: number;

      const animate = () => {
        frame++;
        const progress = frame / totalFrames;
        const easeProgress = 1 - Math.pow(1 - progress, 2);
        
        setDisplayCases(Math.round(stats.totalCases * easeProgress));
        setDisplayTotalAmount(Math.round(stats.totalAmount * easeProgress));
        
        if (frame < totalFrames) {
          animationFrameId = requestAnimationFrame(animate);
        } else {
          setDisplayCases(stats.totalCases);
          setDisplayTotalAmount(stats.totalAmount);
          setIsFirstLoad(false);
        }
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }
  }, [loading, error, estates, isFirstLoad, calculateStats]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-500"></div>
        <span className="ml-3">載入中...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>媒合成功案例</title>
        <meta property="og:title" content="房貸媒合成功案例" />
        <meta property="og:description" content={`累計媒合成功 ${displayCases} 組，總借款金額 ${convertToChineseNumber(displayTotalAmount)}！查看更多成功案例。`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/og-image.jpg" />
        <meta property="og:site_name" content="房貸媒合平台" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="房貸媒合成功案例" />
        <meta name="twitter:description" content={`累計媒合成功 ${displayCases} 組，總借款金額 ${convertToChineseNumber(displayTotalAmount)}！查看更多成功案例。`} />
        <meta name="twitter:image" content="/og-image.jpg" />
      </Helmet>

      {/* 新增統計數據區塊 */}
      <div className="bg-blue-50 rounded-lg p-6 mb-8">
        <div className="grid grid-cols-2 gap-4 text-center">
          <div className="p-4">
            <p className="text-gray-600 mb-2">累計媒合成功</p>
            <p className="text-3xl font-bold text-blue-600">{displayCases} 組</p>
          </div>
          <div className="p-4">
            <p className="text-gray-600 mb-2">累計借款金額</p>
            <p className="text-3xl font-bold text-blue-600">
              {convertToChineseNumber(displayTotalAmount)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">成功媒合案例</h1>
        <div className="text-sm text-gray-500">
          {estates.length} 筆案例
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {estates.map((estate) => (
          <div key={estate.id} className="border rounded-lg p-4 shadow hover:shadow-lg transition-shadow">
            <h2 className="text-xl font-semibold mb-4">{estate.area}</h2>
            <div className="space-y-2">
              <div className="bg-gray-50 p-3 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">基本資訊</h3>
                <p>地址: {estate.address}</p>
                <p>類型: {estate.propertyType}</p>
                <p>屋齡: {estate.buildingAge} 年</p>
                <p>樓高: {estate.floors} 樓</p>
                <p>車位: {estate.parking ? '有' : '無'}</p>
                <p>建物坪數: {estate.buildingSize} 坪</p>
              </div>

              {(estate.firstMortgageProvider || estate.secondMortgageProvider || estate.thirdMortgageProvider) && (
                <div className="bg-blue-50 p-3 rounded-lg">
                  <h3 className="font-semibold text-lg mb-2">原有貸借款資訊</h3>
                  <div className="space-y-2">
                    {estate.firstMortgageProvider && (
                      <div className="border-b pb-2">
                        <p className="font-medium">一胎貸款</p>
                        <p>承作單位: <span className="text-gray-700">{estate.firstMortgageProvider}</span></p>
                        {estate.firstMortgageAmount > 0 && (
                          <p>金額: <span className="text-blue-600">
                            {convertToChineseNumber(estate.firstMortgageAmount)}
                            <span className="text-gray-500 text-sm ml-1">
                              ({estate.firstMortgageAmount.toLocaleString()}元)
                            </span>
                          </span></p>
                        )}
                      </div>
                    )}
                    
                    {estate.secondMortgageProvider && (
                      <div className="border-b pb-2">
                        <p className="font-medium">二胎貸款</p>
                        <p>承作單位: <span className="text-gray-700">{estate.secondMortgageProvider}</span></p>
                        {estate.secondMortgageAmount > 0 && (
                          <p>金額: <span className="text-blue-600">
                            {convertToChineseNumber(estate.secondMortgageAmount)}
                            <span className="text-gray-500 text-sm ml-1">
                              ({estate.secondMortgageAmount.toLocaleString()}元)
                            </span>
                          </span></p>
                        )}
                      </div>
                    )}
                    
                    {estate.thirdMortgageProvider && (
                      <div>
                        <p className="font-medium">三胎貸款</p>
                        <p>承作單位: <span className="text-gray-700">{estate.thirdMortgageProvider}</span></p>
                        {estate.thirdMortgageAmount > 0 && (
                          <p>金額: <span className="text-blue-600">
                            {convertToChineseNumber(estate.thirdMortgageAmount)}
                            <span className="text-gray-500 text-sm ml-1">
                              ({estate.thirdMortgageAmount.toLocaleString()}元)
                            </span>
                          </span></p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="bg-green-50 p-3 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">放款資訊</h3>
                  <p className="font-semibold mb-2">
                    放款團隊: <span className="text-gray-700">{estate.lendingTeam}</span>
                  </p>
                <p className="font-semibold">
                  放款金額: <span className="text-blue-600">
                    {convertToChineseNumber(estate.investmentAmount)}
                    <span className="text-gray-500 text-sm ml-1">
                      ({estate.investmentAmount.toLocaleString()}元)
                    </span>
                  </span>
                </p>
                <p className="font-semibold">
                  年化報酬: <span className="text-green-600">{estate.annualReturn}%</span>
                </p>
                <p>擔保品持有類型: {estate.collateralType}</p>
                <p>擔保品持份比例: {estate.collateralRatio}%</p>
                <p className="font-semibold mt-2">
                  估價: <span className="text-purple-600">
                    {convertToChineseNumber(estate.appraisalValue)}
                    <span className="text-gray-500 text-sm ml-1">
                      ({estate.appraisalValue.toLocaleString()}元)
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessCases; 