import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Team from './components/Team';
import SuccessCases from './components/SuccessCases';
import AgentSearch from './components/AgentSearch';
import PropertyLoan from './components/PropertyLoan';
import BlogPost from './components/BlogPost';
import BlogList from './components/BlogList';
import LoanInProgress from './components/LoanInProgress';
import { ROUTES } from './config/routes';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Navigation />
          <main className="pb-12">
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.TEAM} element={<Team />} />
              <Route path={ROUTES.SUCCESS_CASES} element={<SuccessCases />} />
              <Route path={ROUTES.AGENT_SEARCH} element={<AgentSearch />} />
              <Route path={ROUTES.PROPERTY_LOAN} element={<PropertyLoan />} />
              <Route path={ROUTES.BLOG} element={<BlogPost />} />
              <Route path={ROUTES.BLOGLIST} element={<BlogList />} />
              <Route path={ROUTES.LOAN_IN_PROGRESS} element={<LoanInProgress />} />
              <Route path="*" element={
                <div className="container mx-auto px-4 py-16 text-center">
                  <h1 className="text-4xl font-bold mb-4">找不到頁面</h1>
                  <p className="text-gray-600 mb-8">您所尋找的頁面不存在</p>
                  <a 
                    href={ROUTES.HOME} 
                    className="text-blue-600 hover:text-blue-800"
                  >
                    返回首頁
                  </a>
                </div>
              } />
            </Routes>
          </main>
          <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4">
              <div className="grid md:grid-cols-3 gap-8">
                <div>
                  <h3 className="text-lg font-bold mb-4">聯絡我們</h3>
                  <p>
                    <a
                      href="https://line.me/R/ti/p/@023bgcmj"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-green-500 hover:text-green-700"
                    >
                      <span className="mr-2">加入 LINE 好友</span>
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M19.365 9.863c.349 0 .63.285.63.631 0 .345-.281.63-.63.63H17.61v1.125h1.755c.349 0 .63.283.63.63 0 .344-.281.629-.63.629h-2.386c-.345 0-.627-.285-.627-.629V8.108c0-.345.282-.63.63-.63h2.386c.348 0 .63.285.63.63 0 .349-.282.63-.63.63H17.61v1.125h1.755zm-3.855 3.016c0 .27-.174.51-.432.596-.064.021-.133.031-.199.031-.211 0-.391-.09-.51-.25l-2.443-3.317v2.94c0 .344-.279.629-.631.629-.346 0-.626-.285-.626-.629V8.108c0-.27.173-.51.43-.595.06-.023.136-.033.194-.033.195 0 .375.105.495.254l2.462 3.33V8.108c0-.345.282-.63.63-.63.345 0 .63.285.63.63v4.771zm-5.741 0c0 .344-.282.629-.631.629-.345 0-.627-.285-.627-.629V8.108c0-.345.282-.63.63-.63.346 0 .628.285.628.63v4.771zm-2.466.629H4.917c-.345 0-.63-.285-.63-.629V8.108c0-.345.285-.63.63-.63.348 0 .63.285.63.63v4.141h1.756c.348 0 .629.283.629.63 0 .344-.282.629-.629.629M24 10.314C24 4.943 18.615.572 12 .572S0 4.943 0 10.314c0 4.811 4.27 8.842 10.035 9.608.391.082.923.258 1.058.59.12.301.079.766.038 1.08l-.164 1.02c-.045.301-.24 1.186 1.049.645 1.291-.539 6.916-4.078 9.436-6.975C23.176 14.393 24 12.458 24 10.314"/>
                      </svg>
                    </a>
                    <a
                      href="https://www.facebook.com/people/%E9%87%91%E9%9B%9E%E6%AF%8D%E6%88%BF%E5%B1%8B%E5%9C%9F%E5%9C%B0%E4%BA%8C%E8%83%8E%E5%80%9F%E6%AC%BE%E4%BB%A3%E6%9B%B8%E5%AA%92%E5%90%88%E7%B6%B2/61570461971503/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-blue-500 hover:text-blue-700"
                    >
                      <span className="mr-2">Facebook 粉絲專頁</span>
                      <svg
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                      </svg>
                    </a>
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-bold mb-4">服務項目</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href={ROUTES.AGENT_SEARCH} 
                         className="inline-block px-4 py-2 rounded bg-red-600 hover:bg-red-700 transition-colors duration-200">
                        📋 我是代書尋找標的
                      </a>
                    </li>
                    <li>
                      <a href={ROUTES.PROPERTY_LOAN} 
                         className="inline-block px-4 py-2 rounded bg-blue-600 hover:bg-blue-700 transition-colors duration-200">
                        🏠 我有房地產尋求借款
                      </a>
                    </li>
                    <li>
                      <a href={ROUTES.SUCCESS_CASES} 
                         className="inline-block px-4 py-2 rounded bg-yellow-600 hover:bg-yellow-700 transition-colors duration-200">
                        🌟 媒合成功案例
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-bold mb-4">關於我們</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href={ROUTES.TEAM} 
                         className="inline-block px-4 py-2 rounded bg-purple-600 hover:bg-purple-700 transition-colors duration-200">
                        👥 專業進駐團隊
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-8 pt-8 border-t border-gray-700 text-center text-sm">
                <p>© 2024 金雞母房屋土地代書媒合網 版權所有</p>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
