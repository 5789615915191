import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { TeamMember } from '../types/Team';
import { teamData } from '../data/teamData';
import TeamMemberDetail from './TeamMemberDetail';

const Team: React.FC = () => {
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);
  const teamMembers: TeamMember[] = teamData;

  const numberToChineseUpperCase = (num: number): string => {
    const digits = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'];
    const units = ['', '拾', '佰', '仟', '萬', '拾萬', '佰萬', '仟萬', '億'];
    
    // 內部輔助函數，不加「元」字
    const convertNumber = (num: number): string => {
      if (!num || isNaN(num)) return '零';
      if (num === 0) return '零';
      
      try {
        const numStr = Math.floor(num).toString();
        let result = '';
        
        // 分割數字為億、萬、個位
        const len = numStr.length;
        const yi = Math.floor(len > 8 ? parseInt(numStr.slice(0, len - 8)) : 0);    // 億
        const wan = Math.floor(len > 4 ? parseInt(numStr.slice(Math.max(0, len - 8), len - 4)) : 0);  // 萬
        const ge = Math.floor(parseInt(numStr.slice(Math.max(0, len - 4))));    // 個位數到千位數
        
        // 處理億
        if (yi > 0) {
          result += convertNumber(yi) + '億';
        }
        
        // 處理萬
        if (wan > 0) {
          if (yi > 0 && wan < 1000) result += '零';
          result += convertNumber(wan) + '萬';
        }
        
        // 處理個位數到千位數
        if (ge > 0) {
          if ((yi > 0 || wan > 0) && ge < 1000) result += '零';
          const geStr = ge.toString();
          for (let i = 0; i < geStr.length; i++) {
            const digit = parseInt(geStr[i]);
            const position = geStr.length - 1 - i;
            
            if (digit !== 0) {
              result += digits[digit] + (position > 0 ? units[position] : '');
            } else if (position > 0 && result[result.length - 1] !== '零') {
              result += '零';
            }
          }
        } else if (yi > 0 || wan > 0) {
          // 如果個位數部分為0，且前面有值，不需要加零
        } else {
          result = '零';
        }
        
        // 清理結尾的零和重複的零
        result = result.replace(/零+$/, '');
        result = result.replace(/零+/g, '零');
        
        return result;
      } catch (error) {
        console.error('轉換數字時發生錯誤:', error);
        return '格式錯誤';
      }
    };

    // 主函數最後才加上「元」字
    return convertNumber(num) + '元';
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>專業團隊 - 金雞母房屋土地代書媒合網</title>
      </Helmet>

      <h1 className="text-3xl font-bold text-center mb-8">專業團隊</h1>

      <div className="bg-blue-50 rounded-lg p-6 mb-12">
        <div className="grid grid-cols-2 gap-4 text-center">
          <div className="p-4">
            <p className="text-gray-600 mb-2">累計進駐團隊</p>
            <p className="text-3xl font-bold text-blue-600">{teamMembers.length} 組</p>
          </div>
          <div className="p-4">
            <p className="text-gray-600 mb-2">累計放款金額</p>
            <p className="text-3xl font-bold text-blue-600">
              {numberToChineseUpperCase(teamMembers.reduce((sum, member) => 
                sum + (typeof member.totalLoanAmount === 'number' ? member.totalLoanAmount : 0), 0
              ))}
            </p>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {teamMembers.map(member => (
          <div
            key={member.id}
            className="bg-white rounded-lg shadow-lg p-6 cursor-pointer hover:shadow-xl transition-shadow duration-300"
            onClick={() => setSelectedMember(member)}
          >
            <h2 className="text-xl font-bold mb-2">{member.name}</h2>
            <p className="text-gray-600 mb-2">{member.title}</p>
            <p className="text-gray-700 mb-4">{member.description}</p>
            
            <div className="mb-4">
              <p className="text-gray-600">
                <span className="font-semibold">公司：</span>{member.companyName}
              </p>
              <p className="text-gray-600">
                <span className="font-semibold">地點：</span>{member.location}
              </p>
            </div>

            <div className="mb-4">
              <p className="text-green-600">
                <span className="font-semibold">已協助案件：</span>{member.casesCompleted} 件
              </p>
              <p className="text-green-600">
                <span className="font-semibold">累計放款金額：</span>
                {(() => {
                  console.log('金額數值:', member.totalLoanAmount);
                  return typeof member.totalLoanAmount === 'number' 
                    ? numberToChineseUpperCase(member.totalLoanAmount)
                    : '資料錯誤';
                })()}
              </p>
            </div>

            <div className="flex flex-wrap gap-2">
              {member.specialties.map(specialty => (
                <span key={specialty} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                  {specialty}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
        <TeamMemberDetail
          member={selectedMember}
          onClose={() => setSelectedMember(null)}
        />
      )}
    </div>
  );
};

export default Team; 