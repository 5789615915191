import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../config/routes';

const Navigation: React.FC = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-gray-800 text-white">
      <div className="container mx-auto px-4">
        {/* 桌面版導航 */}
        <div className="hidden md:flex justify-between items-center h-16">
          <Link to={ROUTES.HOME} className="text-xl font-bold">
          🐣金雞母房屋土地二胎借款代書媒合網
          </Link>
          <div className="space-x-4">
            <Link
              to={ROUTES.TEAM}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.TEAM ? 'bg-purple-600' : 'hover:bg-gray-700'
              }`}
            >
              👥 進駐團隊
            </Link>
            <Link
              to={ROUTES.LOAN_IN_PROGRESS}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.LOAN_IN_PROGRESS ? 'bg-green-600' : 'hover:bg-gray-700'
              }`}
            >
              💰 尋求借款
            </Link>
            <Link
              to={ROUTES.SUCCESS_CASES}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.SUCCESS_CASES ? 'bg-yellow-600' : 'hover:bg-gray-700'
              }`}
            >
              🌟 媒合成功
            </Link>
            <Link
              to={ROUTES.AGENT_SEARCH}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.AGENT_SEARCH ? 'bg-red-600' : 'hover:bg-gray-700'
              }`}
            >
              📋 我是代書尋求好標的
            </Link>
            <Link
              to={ROUTES.PROPERTY_LOAN}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.PROPERTY_LOAN ? 'bg-blue-600' : 'hover:bg-gray-700'
              }`}
            >
              🏠 我有房地產尋求二胎借款
            </Link>
          </div>
        </div>
        
        {/* 手機版導航 */}
        <div className="md:hidden">
          <div className="flex justify-between items-center h-14">
            <Link to={ROUTES.HOME} className="text-lg font-bold">
            🐣金雞母房屋土地二胎借款代書媒合網
            </Link>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded hover:bg-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* 手機版選單 */}
          {isMenuOpen && (
            <div className="py-2 space-y-1">
              <Link
                to={ROUTES.TEAM}
                className={`block px-4 py-2 rounded ${
                  location.pathname === ROUTES.TEAM ? 'bg-purple-600' : 'hover:bg-gray-700'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                👥 進駐團隊
              </Link>
              <Link
                to={ROUTES.LOAN_IN_PROGRESS}
                className={`block px-4 py-2 rounded ${
                  location.pathname === ROUTES.LOAN_IN_PROGRESS ? 'bg-green-600' : 'hover:bg-gray-700'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                💰 尋求借款
              </Link>
              <Link
                to={ROUTES.SUCCESS_CASES}
                className={`block px-4 py-2 rounded ${
                  location.pathname === ROUTES.SUCCESS_CASES ? 'bg-yellow-600' : 'hover:bg-gray-700'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                🌟 媒合成功
              </Link>
              
              {/* 媒合服務選項 */}
              <div className="px-4 py-2">
                <div className="font-medium mb-2">我要媒合</div>
                <Link
                  to={ROUTES.AGENT_SEARCH}
                  className="block pl-4 py-2 text-sm hover:bg-gray-700 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  📋 我是代書尋求好標的
                </Link>
                <Link
                  to={ROUTES.PROPERTY_LOAN}
                  className="block pl-4 py-2 text-sm hover:bg-gray-700 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  🏠 我有房地產尋求借款
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
