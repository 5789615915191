import React from 'react';
import BlogList from './BlogList';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config/routes';

const Home: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>🐣金雞母房地產金融平台 媒合賺取穩定的房地產收益</title>
      </Helmet>
      
      <div className="flex gap-4 justify-center mb-8">
        <Link 
          to={ROUTES.AGENT_SEARCH}
          className="flex-1 max-w-md py-4 px-6 bg-red-600 text-white rounded-lg text-center text-xl hover:bg-red-700 transition-colors shadow-lg"
        >
          📋 我是代書尋求好標的
        </Link>
        
        <Link 
          to={ROUTES.PROPERTY_LOAN}
          className="flex-1 max-w-md py-4 px-6 bg-blue-600 text-white rounded-lg text-center text-xl hover:bg-blue-700 transition-colors shadow-lg"
        >
          🏠 我有房地產尋求二胎借款
        </Link>
      </div>

      <BlogList />
    </div>
  );
};

export default Home; 