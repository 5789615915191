import React from 'react';
import { Helmet } from 'react-helmet-async';

const PropertyLoan: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>房地產借款申請 - 金雞母房屋土地代書媒合網</title>
        <meta name="description" content="快速便捷的房地產借款服務，專業團隊協助評估" />
      </Helmet>

      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">房地產借款申請</h1>

        {/* 服務特色 */}
        <div className="grid md:grid-cols-3 gap-6 mb-12">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-blue-600 text-4xl mb-4">⚡</div>
            <h3 className="text-xl font-semibold mb-2">快速審核</h3>
            <p className="text-gray-600">24小時內完成初步評估</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-blue-600 text-4xl mb-4">💰</div>
            <h3 className="text-xl font-semibold mb-2">優質��率</h3>
            <p className="text-gray-600">提供市場最優惠利率方案</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="text-blue-600 text-4xl mb-4">🤝</div>
            <h3 className="text-xl font-semibold mb-2">專業服務</h3>
            <p className="text-gray-600">一對一專屬服務團隊</p>
          </div>
        </div>

        {/* 申請按鈕 */}
        <div className="bg-white rounded-lg shadow-lg p-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">立即申請房地產借款</h2>
          <p className="text-gray-600 mb-6">
            請點擊下方按鈕填寫申請表，我們將盡快與您聯繫。
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSf5D7gj01YkfS59d4Fjt-QkVHLbF2wOxagRIBxgXNzDOS7tvg/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            填寫借款申請表
          </a>
        </div>

      </div>
    </div>
  );
};

export default PropertyLoan; 