import React from 'react';
import { Helmet } from 'react-helmet-async';

const AgentSearch: React.FC = () => {
  const handleFormClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSeNesoMgWpkASVW-zz1eYXLw3TpDLzA4M1gjCumID9QQZ0B5Q/viewform',
      '_blank'
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>代書尋找標的 - 金雞母房屋土地代書媒合網</title>
        <meta name="description" content="代書專區 - 快速找到符合需求的房地產標的" />
      </Helmet>

      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">代書尋找標的</h1>
        
        {/* 服務說明 */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">為什麼選擇我們？</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>專業團隊篩選優質標的</li>
            <li>快速媒合最適合的物件</li>
            <li>完整的法律諮詢服務</li>
            <li>保障交易安全與隱私</li>
          </ul>
        </div>

        {/* 將 iframe 改成按鈕 */}
        <div className="bg-white rounded-lg shadow-lg p-6 text-center">
          <button
            onClick={handleFormClick}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded-lg text-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            點擊填寫申請表單
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentSearch; 