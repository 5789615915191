import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BlogPost } from '../types/BlogPost';
import { blogService } from '../services/blogService';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '../config/routes';
import { formatDate } from '../utils/dateUtils';

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setRelatedPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (slug) {
          const fetchedPost = await blogService.getPostBySlug(slug);
          setPost(fetchedPost || null);

          // 獲取相關文章
          if (fetchedPost) {
            const related = await blogService.getRelatedPosts(fetchedPost.tags, slug);
            setRelatedPosts(related);
          }
        }
      } catch (error) {
        console.error('Failed to fetch post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  const removeFrontMatter = (content: string) => {
    // 移除開頭的 YAML frontmatter
    return content.replace(/^---[\s\S]*?---\n/, '');
  };

  if (loading) {
    return <div className="text-center py-8">載入中...</div>;
  }

  if (!post) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">找不到文章</h1>
          <Link to="/" className="text-blue-600 hover:text-blue-800">
            返回首頁
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} - 房地產金融平台</title>
        <meta name="description" content={post.excerpt || post.description} />
        <meta name="keywords" content={post.tags.join(',')} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={post.publishDate} />
        <meta property="article:author" content={post.author} />
        {post.tags.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      </Helmet>

      <article className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="mb-8">
          <Link to="/blogs" className="text-blue-600 hover:text-blue-800">
            ← 返回文章列表
          </Link>
        </div>
        
        {post.coverImage && (
          <img
            src={post.coverImage.src}
            alt={post.coverImage.alt}
            className="w-full h-64 object-cover rounded-lg mb-8"
          />
        )}

        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        
        <div className="flex items-center text-gray-600 mb-8">
          <span>{post.author}</span>
          <span className="mx-2">•</span>
          <time dateTime={post.publishDate}>
            {formatDate(post.publishDate)}
          </time>
        </div>

        <div className="prose prose-lg max-w-none">
          {post.excerpt && (
            <div className="text-xl text-gray-600 mb-8 font-medium">
              {post.excerpt}
            </div>
          )}
          
          {post.youtubeUrl && (
            <div className="aspect-w-16 aspect-h-9 mb-8">
              <iframe
                src={`https://www.youtube.com/embed/${post.youtubeUrl.split('v=')[1]}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          )}

          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="markdown-body"
          >
            {removeFrontMatter(post.content)}
          </ReactMarkdown>
        </div>

        <div className="mt-8 pt-8 border-t">
          <div className="flex flex-wrap gap-2">
            {post.tags.map(tag => (
              <span
                key={tag}
                className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>

        {relatedPosts.length > 0 && (
          <div className="mt-16 border-t pt-8">
            <h2 className="text-2xl font-bold mb-6">相關文章</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {relatedPosts.map(relatedPost => (
                <Link
                  key={relatedPost.slug}
                  to={`/blog/${relatedPost.slug}`}
                  className="group"
                >
                  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
                    {relatedPost.coverImage && (
                      <img
                        src={relatedPost.coverImage.src}
                        alt={relatedPost.coverImage.alt}
                        className="w-full h-48 object-cover"
                      />
                    )}
                    <div className="p-4">
                      <h3 className="text-lg font-semibold mb-2 group-hover:text-blue-600">
                        {relatedPost.title}
                      </h3>
                      <p className="text-gray-600 text-sm line-clamp-2">
                        {relatedPost.excerpt}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}

        <div className="mt-8 flex gap-4 justify-center">
          <a 
            href={ROUTES.AGENT_SEARCH}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            📋 我是代書尋求好標的
          </a>
          <a
            href={ROUTES.PROPERTY_LOAN}
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            🏠 我有房地產尋求二胎借款
          </a>
        </div>
      </article>
    </>
  );
};

export default BlogPostPage;
