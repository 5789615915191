import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { GOOGLE_SCRIPT_CONFIG } from '../config/constants';

interface LoanCase {
  id: number;
  area: string;
  address: string;
  propertyType: string;
  buildingAge: number;
  floors: number;
  parking: boolean;
  buildingSize: number;
  investmentAmount: number;
  annualReturn: number;
  collateralType: string;
  collateralRatio: number;
  appraisalValue: number;
  firstMortgageProvider: string;
  firstMortgageAmount: number;
  secondMortgageProvider: string;
  secondMortgageAmount: number;
  thirdMortgageProvider: string;
  thirdMortgageAmount: number;
  lendingTeam: string;
  status: string;
  updatedAt: string;
}

const convertToChineseNumber = (amount: number): string => {
  const units = ['', '萬', '億', '兆'];
  let result = '';
  
  if (amount === 0) return '0元';
  
  let temp = amount;
  let unitIndex = 0;
  
  while (temp >= 1) {
    const part = temp % 10000;
    if (part !== 0) {
      result = part + units[unitIndex] + result;
    }
    temp = Math.floor(temp / 10000);
    unitIndex++;
  }
  
  return result + '元';
};

const LoanInProgress: React.FC = () => {
  const [loanCases, setLoanCases] = useState<LoanCase[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');

  const fetchLoanCases = async () => {
    try {
      const jsonUrl = `${GOOGLE_SCRIPT_CONFIG.baseUrl}${GOOGLE_SCRIPT_CONFIG.endpoints.loans}`;
      const result = await fetch(jsonUrl)
        .then(res => res.json())
        .catch(() => ({ status: 'error', message: '資料格式錯誤' }));

      if (result.status === 'success') {
        setLoanCases(result.data);
        setError('');
      } else {
        throw new Error(result.message || '資料格式錯誤');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('資料讀取發生錯誤，請稍後再試');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanCases();
    const interval = setInterval(fetchLoanCases, 300000);
    return () => clearInterval(interval);
  }, []);

  // 計算總借款金額
  const totalLoanAmount = loanCases.reduce((sum, loan) => sum + (loan.investmentAmount || 0), 0);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-500"></div>
        <span className="ml-3">載入中...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>尋求借款中 - 金雞母房屋土地代書媒合網</title>
        <meta name="description" content="查看目前正在進行中的借款案件" />
      </Helmet>

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">尋求借款中案件</h1>
        <div className="text-sm text-gray-500">
          目前 {loanCases.length} 組，共尋求借款 {convertToChineseNumber(totalLoanAmount)}
          <span className="block text-right">({totalLoanAmount.toLocaleString()} 元)</span>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-gray-600">地區</th>
              <th className="px-6 py-3 text-left text-gray-600">物件類型</th>
              <th className="px-6 py-3 text-left text-gray-600">建物資訊</th>
              <th className="px-6 py-3 text-left text-gray-600">估值</th>
              <th className="px-6 py-3 text-left text-gray-600">尋求借款金額</th>
              <th className="px-6 py-3 text-left text-gray-600">貸款狀態</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loanCases.map(loan => {
              const mortgageStatus = [
                loan.firstMortgageAmount ? `一胎${convertToChineseNumber(loan.firstMortgageAmount)}` : null,
                loan.secondMortgageAmount ? `二胎${convertToChineseNumber(loan.secondMortgageAmount)}` : null,
                loan.thirdMortgageAmount ? `三胎${convertToChineseNumber(loan.thirdMortgageAmount)}` : null,
              ].filter(Boolean).join('、') || '無';

              return (
                <tr key={loan.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">{loan.area}</td>
                  <td className="px-6 py-4">{loan.propertyType}</td>
                  <td className="px-6 py-4">
                    {loan.buildingSize && <div>坪數: {loan.buildingSize}坪</div>}
                    {loan.buildingAge && <div>屋齡: {loan.buildingAge}年</div>}
                  </td>
                  <td className="px-6 py-4">
                    {loan.appraisalValue ? (
                      <span className="text-green-600">
                        {convertToChineseNumber(loan.appraisalValue)}
                        <span className="text-gray-500 text-sm block">
                          ({loan.appraisalValue.toLocaleString()}元)
                        </span>
                      </span>
                    ) : (
                      <span>待估價</span>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {loan.investmentAmount ? (
                      <span className="text-blue-600">
                        {convertToChineseNumber(loan.investmentAmount)}
                        <span className="text-gray-500 text-sm block">
                          ({loan.investmentAmount.toLocaleString()}元)
                        </span>
                      </span>
                    ) : (
                      <span>待定</span>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                      {mortgageStatus}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoanInProgress; 