// 環境變數配置
const ENV_CONFIG = {
  development: {
    apiUrl: '開發環境 URL',
    refreshInterval: 60000, // 開發環境 1 分鐘更新
  },
  test: {
    apiUrl: '測試環境 URL',
    refreshInterval: 180000, // 測試環境 3 分鐘更新
  },
  production: {
    apiUrl: '正式環境 URL',
    refreshInterval: 300000, // 正式環境 5 分鐘更新
  },
} as const;

// 獲取當前環境配置
const getCurrentEnvConfig = () => {
  const env = process.env.NODE_ENV || 'development';
  return ENV_CONFIG[env as keyof typeof ENV_CONFIG];
};

// API 相關配置
export const API_CONFIG = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  baseUrl: getCurrentEnvConfig().apiUrl,
  refreshInterval: getCurrentEnvConfig().refreshInterval,
  endpoints: {
    team: '/team',
    cases: '/cases',
    loans: '/loans',
  },
} as const;

// 路由配置
export const ROUTES = {
  HOME: '/',
  INVEST: '/invest',
  LOAN: '/loan',
  TEAM: '/team',
  CASES: '/cases',
} as const;

// Google Script 相關配置
export const GOOGLE_SCRIPT_CONFIG = {
  baseUrl: 'https://script.google.com/macros/s/AKfycbwEjPzMEBh2gpQ8eom2UaA7y3VW0kWIttsQ_PqcJVMiqP9Jx9m934tk9sGCmeqj425T3Q/exec',
  endpoints: {
    team: '?path=team',
    cases: '?path=cases',
    loans: '?path=loans',
  },
} as const;


